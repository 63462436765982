import React from "react"
import { HcpPage, SEO, Expandable } from 'components';
import { Row, Col } from 'react-flexbox-grid';
import { HCPResourcs, BecomePrescriberCallout } from "components/shared/callouts";
import { withPrefix } from 'gatsby'

import './paying-for-aveed.scss';
import 'scss/copay-box.scss';

const pageDescription = "Does your patient qualify for AVEED® (testosterone undecanoate) injection CIII patient savings? Read about the savings program eligibility. See Safety & PI, including Boxed Warning.";

const PayingForAveedPage = () => {
  return (
    <HcpPage pageClassName="paying-for-aveed">
      <SEO pageTitle="Paying for AVEED® " pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <Row>
          <Col xs={12}>
            <h1>Help Your Eligible Patients Save</h1>
            <Row>
              <Col xs={12} md={6}>
                <div className="gradient-wrapper">
                  <div className="copay-box">
                    <div className="inner-content">
                      <p>MAJORITY OF ELIGIBLE PATIENTS<br />WITH COMMERCIAL INSURANCE PLANS</p>
                      <p className="txt-outofpacket">PAID <sup className="dollar-sign">$</sup><span className="zero-numero">0</span> OUT-OF-POCKET*</p>
                      <p className="txt-assistance-program">
                        <span>
                          with the AVEED<sup>&reg;</sup> Copay Assistance Program</span></p>
                      <p className="txt-footer">*January 2016 through May 2020 = 21,188 claims filed.</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <p>Depending on your patients' insurance, and other relevant factors, they may be eligible for savings with the AVEED<sup>&reg;</sup> Copay Assistance Program.</p>
            <p><strong>How much patients pay for AVEED<sup>&reg;</sup> will depend on their health benefits or insurance coverage.</strong></p>
            <ul className="green">
              <li><p>With the AVEED<sup>&reg;</sup> Copay Assistance Program, eligible patients with commercial insurance plans may pay $0 copay for AVEED<sup>&reg;</sup></p></li>
              <li><p>The AVEED<sup>&reg;</sup> Copay Assistance Program can cover up to $300 of patients' out-of-pocket costs for each injection of AVEED<sup>&reg;</sup></p></li>
            </ul>
            <p>If their total out-of-pocket expense exceeds these amounts, patients are responsible for the <nobr>additional amounts.</nobr></p>

            <Expandable linkTitle="Some restrictions apply" className="expandable-restriction-text">
              <ul className="green">
                <li><span>Offer good only in the USA and void where prohibited or otherwise restricted by law</span></li>
                <li><span>Offer is valid for patients with private insurance or paying cash</span></li>
                <li><span>Offer not valid for prescriptions that may be reimbursed under a federal or state healthcare program, including Medicare, Medicaid, or any other federal or state healthcare programs, including any state medical pharmaceutical assistance programs</span></li>
              </ul>
              <p>If there are any questions, please call 1-800-381-2638.</p>
              <p>Limitations apply. Endo Pharmaceuticals Inc. reserves the right to rescind, revoke, or amend this program without notice.</p>
              <p>Patient is responsible for reporting receipt of program rewards to any private insurer that pays for or reimburses any part of the prescriptions filled with this program.</p>
            </Expandable>

            <div className="green-box">
              <p className="strong box-head">Help your patients receive a rebate on AVEED<sup>&reg;</sup></p>
              <p>If you order AVEED<sup>&reg;</sup> for your patients through a Specialty Pharmacy, your eligible patients can receive a rebate on AVEED<sup>&reg;</sup> by directly contacting their Specialty Pharmacy and asking about the AVEED<sup>&reg;</sup> Copay Assistance Program.</p>
              <p>If you order AVEED<sup>&reg;</sup> for patients through Buy-and-Bill, you can download and print the rebate form for your eligible patients and have them complete and submit it.</p>
              <a href={withPrefix('/pdfs/AV-05294_AVEED_Copay_Assistance_Program_Form_Final.pdf')} target="_blank" className="aveed-button green" rel="noopener noreferrer">Download Rebate Forms for Your Patients</a>
            </div>
          </Col>
        </Row>

        <Row className="callout-blocks-container">
          <Col xs={12} md={6}>
            <HCPResourcs />
          </Col>
          <Col xs={12} md={6}>
            <BecomePrescriberCallout />
          </Col>
          <Col xs={12} className="pt-10">
            <p className="footnote"><sup >&dagger;</sup>Risk Evaluation and Mitigation Strategy.</p>
          </Col>
        </Row>
      </div>
    </HcpPage>
  )
}

export default PayingForAveedPage